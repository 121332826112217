/**
 * @method GET
 * @description Быстрый поиск по сущностям
 * */

interface IApiContentSearchQuickProps {
  query: string
  limit: number
}

export default async function apiContentSearchQuick(payload: IApiContentSearchQuickProps) {
  const endpoint = `/content/v1/search/${payload.query}?limit=${payload.limit}`

  return await useAsyncQuery(async ({ axios, path }) => {
    return await axios.get(path + endpoint)
  })
}
